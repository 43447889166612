import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../layout/layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image";

import {
  Container,
  Columns,
  Column,
  Section,
  Breadcrumb,
  BreadcrumbItem,
  Icon
} from "bloomer";

export default class ShopPage extends React.Component {
  render() {
    const recordings = this.props.data.products
      ? this.props.data.products.edges
      : null;

    const template = (
      <Layout>
        <Helmet>
          <title>{`Shop | ${config.siteTitle}`}</title>
        </Helmet>

        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>Shop</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <h1 className="title is-1">Shop</h1>
            <hr />

            <Columns className="recordings" isMobile isMultiline>
              {recordings.map(({ node }) => (
                <Column
                  isSize={{ mobile: 12, tablet: 6, desktop: 4, widescreen: 4 }}
                >
                  <div class="tag is-dark is-uppercase mb-2">{node.frontmatter.type}</div>
                  <Link to={`/products/${node.fields.id}`}>
                    <div class="relative">
                      <Img
                        fluid={
                          node.frontmatter.images[0].childImageSharp.thumbnail
                        }
                        alt={node.title}
                      />

                      {node.frontmatter.tag ? (
                        <div class="ribbon-wrapper">
                          <div
                            className={`ribbon ${node.frontmatter.tag.type}`}
                          >
                            {node.frontmatter.tag.text}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Link>
                </Column>
              ))}
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
    return template;
  }
}

export const pageQuery = graphql`
  query ShopQuery {
    products: allMarkdownRemark(
      filter: { fields: { template: { eq: "products" } } }
      sort: { fields: [frontmatter___release_date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
            id
          }
          frontmatter {
            id
            release_date
            title
            price
            teaser
            type
            tag {
              text
              type
            }
            images {
              childImageSharp {
                thumbnail: fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            links {
              label
              url
            }
          }
        }
      }
    }
  }
`;
